<template>
  <div class="image-input">
    <img :src="image" alt="">
    <span class="tool tool__change" @click="handleImageChange">
      <i class="el-icon-edit"></i>
      <input type="file" @change="handleFileChange" ref="inputFile" accept=".png, .jpg, .jpeg">
    </span>
    <span class="tool tool__remove" @click="handleClearImage"><i class="el-icon-close"></i></span>
  </div>
</template>

<style lang="scss">
.image-input {
  position: relative;
  display: inline-block;
  border-radius: 5.46px;

  img {
    border: 3px solid #fff;
    width: 120px;
    height: 120px;
    box-shadow: 0px 6px 10px 6px rgba(0, 0, 0, 0.075);
    border-radius: 5.46px;
  }

  .tool {
    box-shadow: 0px 9px 16px 0px rgba(24, 28, 50, 0.25) !important;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    cursor: pointer;
    background-color: #ffffff;
    border-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;

  i {
    color: #B5B5C3 !important;
    font-size: 12px;
  }

  &__change {
     position: absolute;
     top: -7px;
     right: -8px;

  input {
    width: 0 !important;
    height: 0 !important;
  }
  }

  &__remove {
     position: absolute;
     bottom: 7px;
     right: -8px;
   }
  }
}
</style>

<script>
export default {
  props: {
    image: String,
    imageEmpty: String
  },
  data () {
    return {
    }
  },
  methods: {
    handleImageChange () {
      this.$refs.inputFile.click()
    },
    handleClearImage () {
      if (this.image.startsWith('blob')) {
        URL.revokeObjectURL(this.image)
      }
      this.$refs.inputFile.value = null
      this.$emit('update:image', this.imageEmpty)
    },
    handleFileChange (e) {
      const img = URL.createObjectURL(e.target.files[0])
      this.$emit('update:image', img)
      this.$emit('image-object', e.target.files[0])
    }
  },
  mounted () {
    if (!this.image) this.$emit('update:image', this.imageEmpty)
  }
}
</script>
