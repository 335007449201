<template>
  <gf-base>
    <gf-card subtitle="Create New Product" title="New Product">
      <template #toolbar>
        <BackButton @click="back" />
      </template>
      <el-dialog
        v-loading="newBrandModalLoading"
        :close-on-click-modal="false"
        :visible.sync="newBrandModalVisible"
        append-to-body
        title="Add New Brand"
      >
        <gf-form>
          <template #alert>
            <gf-alert>
              <template #icon>
                <span class="svg-icon svg-icon-primary svg-icon-md">
                  <svg
                    height="24px"
                    version="1.1"
                    viewBox="0 0 24 24"
                    width="24px"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <g
                      fill="none"
                      fill-rule="evenodd"
                      stroke="none"
                      stroke-width="1"
                    >
                      <rect height="24" width="24" x="0" y="0" />
                      <path
                        d="M12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 Z M11.613922,13.2130341 C11.1688026,13.6581534 10.4887934,13.7685037 9.92575695,13.4869855 C9.36272054,13.2054673 8.68271128,13.3158176 8.23759191,13.760937 L6.72658218,15.2719467 C6.67169475,15.3268342 6.63034033,15.393747 6.60579393,15.4673862 C6.51847004,15.7293579 6.66005003,16.0125179 6.92202169,16.0998418 L8.27584113,16.5511149 C9.57592638,16.9844767 11.009274,16.6461092 11.9783003,15.6770829 L15.9775173,11.6778659 C16.867756,10.7876271 17.0884566,9.42760861 16.5254202,8.3015358 L15.8928491,7.03639343 C15.8688153,6.98832598 15.8371895,6.9444475 15.7991889,6.90644684 C15.6039267,6.71118469 15.2873442,6.71118469 15.0920821,6.90644684 L13.4995401,8.49898884 C13.0544207,8.94410821 12.9440704,9.62411747 13.2255886,10.1871539 C13.5071068,10.7501903 13.3967565,11.4301996 12.9516371,11.8753189 L11.613922,13.2130341 Z"
                        fill="#000000"
                      />
                    </g>
                  </svg>
                </span>
              </template>
              Input the details of the new brand below
            </gf-alert>
          </template>
          <el-form
            ref="brand"
            :model="brand"
            :rules="brandRule"
            class="auto-form-item-width"
            label-width="70px"
            size="small"
          >
            <el-form-item label="Brand" prop="name">
              <el-input
                v-model="brand.name"
                placeholder="Brand Name"
              ></el-input>
            </el-form-item>
          </el-form>
          <template #footer>
            <gf-button type="secondary" @click="newBrandModalVisible = false">
              <template slot="icon">
                <span class="svg-icon svg-icon-sm">
                  <svg
                    height="24px"
                    version="1.1"
                    viewBox="0 0 24 24"
                    width="24px"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <g
                      fill="none"
                      fill-rule="evenodd"
                      stroke="none"
                      stroke-width="1"
                    >
                      <g
                        fill="#000000"
                        transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)"
                      >
                        <rect height="2" rx="1" width="16" x="0" y="7" />
                        <rect
                          height="2"
                          opacity="0.3"
                          rx="1"
                          transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) "
                          width="16"
                          x="0"
                          y="7"
                        />
                      </g>
                    </g>
                  </svg>
                </span>
              </template>
              Close
            </gf-button>
            <gf-button type="primary" @click="newBrand">
              <template slot="icon">
                <span class="svg-icon svg-icon-white svg-icon-sm">
                  <svg
                    height="24px"
                    version="1.1"
                    viewBox="0 0 24 24"
                    width="24px"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <g
                      fill="none"
                      fill-rule="evenodd"
                      stroke="none"
                      stroke-width="1"
                    >
                      <rect
                        fill="#000000"
                        height="2"
                        rx="1"
                        width="16"
                        x="4"
                        y="11"
                      />
                      <rect
                        fill="#000000"
                        height="2"
                        opacity="0.3"
                        rx="1"
                        transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) "
                        width="16"
                        x="4"
                        y="11"
                      />
                    </g>
                  </svg>
                </span>
              </template>
              Add
            </gf-button>
          </template>
        </gf-form>
      </el-dialog>
      <el-dialog
        v-loading="newProductTypeModalLoading"
        :close-on-click-modal="false"
        :visible.sync="newProductTypeModalVisible"
        append-to-body
        title="Add New Product Type"
      >
        <gf-form>
          <template #alert>
            <gf-alert>
              <template #icon>
                <span class="svg-icon svg-icon-primary svg-icon-md">
                  <svg
                    height="24px"
                    version="1.1"
                    viewBox="0 0 24 24"
                    width="24px"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <g
                      fill="none"
                      fill-rule="evenodd"
                      stroke="none"
                      stroke-width="1"
                    >
                      <rect height="24" width="24" x="0" y="0" />
                      <path
                        d="M12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 Z M11.613922,13.2130341 C11.1688026,13.6581534 10.4887934,13.7685037 9.92575695,13.4869855 C9.36272054,13.2054673 8.68271128,13.3158176 8.23759191,13.760937 L6.72658218,15.2719467 C6.67169475,15.3268342 6.63034033,15.393747 6.60579393,15.4673862 C6.51847004,15.7293579 6.66005003,16.0125179 6.92202169,16.0998418 L8.27584113,16.5511149 C9.57592638,16.9844767 11.009274,16.6461092 11.9783003,15.6770829 L15.9775173,11.6778659 C16.867756,10.7876271 17.0884566,9.42760861 16.5254202,8.3015358 L15.8928491,7.03639343 C15.8688153,6.98832598 15.8371895,6.9444475 15.7991889,6.90644684 C15.6039267,6.71118469 15.2873442,6.71118469 15.0920821,6.90644684 L13.4995401,8.49898884 C13.0544207,8.94410821 12.9440704,9.62411747 13.2255886,10.1871539 C13.5071068,10.7501903 13.3967565,11.4301996 12.9516371,11.8753189 L11.613922,13.2130341 Z"
                        fill="#000000"
                      />
                    </g>
                  </svg>
                </span>
              </template>
              Input the details of the new product type below
            </gf-alert>
          </template>
          <el-form
            ref="productType"
            :model="productType"
            :rules="productTypeRule"
            class="auto-form-item-width"
            label-width="120px"
            size="small"
          >
            <el-form-item label="Product Type" prop="name">
              <el-input
                v-model="productType.name"
                placeholder="Product Type Name"
              ></el-input>
            </el-form-item>
          </el-form>
          <template #footer>
            <gf-button
              type="secondary"
              @click="newProductTypeModalVisible = false"
            >
              <template slot="icon">
                <span class="svg-icon svg-icon-sm">
                  <svg
                    height="24px"
                    version="1.1"
                    viewBox="0 0 24 24"
                    width="24px"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <g
                      fill="none"
                      fill-rule="evenodd"
                      stroke="none"
                      stroke-width="1"
                    >
                      <g
                        fill="#000000"
                        transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)"
                      >
                        <rect height="2" rx="1" width="16" x="0" y="7" />
                        <rect
                          height="2"
                          opacity="0.3"
                          rx="1"
                          transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) "
                          width="16"
                          x="0"
                          y="7"
                        />
                      </g>
                    </g>
                  </svg>
                </span>
              </template>
              Close
            </gf-button>
            <gf-button type="primary" @click="newProductType">
              <template slot="icon">
                <span class="svg-icon svg-icon-white svg-icon-sm">
                  <svg
                    height="24px"
                    version="1.1"
                    viewBox="0 0 24 24"
                    width="24px"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <g
                      fill="none"
                      fill-rule="evenodd"
                      stroke="none"
                      stroke-width="1"
                    >
                      <rect
                        fill="#000000"
                        height="2"
                        rx="1"
                        width="16"
                        x="4"
                        y="11"
                      />
                      <rect
                        fill="#000000"
                        height="2"
                        opacity="0.3"
                        rx="1"
                        transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) "
                        width="16"
                        x="4"
                        y="11"
                      />
                    </g>
                  </svg>
                </span>
              </template>
              Add
            </gf-button>
          </template>
        </gf-form>
      </el-dialog>
      <gf-container v-if="!summaryPage">
        <gf-form v-loading="loading" :width="800">
          <el-form
            ref="product"
            :model="product"
            :rules="productRule"
            label-width="170px"
            size="medium"
          >
            <el-form-item>
              <gf-alert>
                <template #icon>
                  <span class="svg-icon svg-icon-primary svg-icon-md">
                    <svg
                      height="24px"
                      version="1.1"
                      viewBox="0 0 24 24"
                      width="24px"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                    >
                      <g
                        fill="none"
                        fill-rule="evenodd"
                        stroke="none"
                        stroke-width="1"
                      >
                        <rect height="24" width="24" x="0" y="0" />
                        <path
                          d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z"
                          fill="#000000"
                          fill-rule="nonzero"
                          transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953) "
                        />
                        <path
                          d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z"
                          fill="#000000"
                          fill-rule="nonzero"
                          opacity="0.3"
                        />
                      </g>
                    </svg>
                  </span>
                </template>
                Provide details of this product below.
              </gf-alert>
            </el-form-item>
            <el-form-item prop="is_manufactured">
              <el-checkbox
                v-model="product.is_manufactured"
                @change="helpIndex = 'isManufactured'"
                >This is a manufactured product</el-checkbox
              >
            </el-form-item>
            <el-form-item
              v-if="product.is_manufactured"
              class="mt-n5"
              prop="is_draft"
            >
              <el-checkbox
                v-model="product.is_draft"
                @change="helpIndex = 'isDraft'"
                >This product is not yet done</el-checkbox
              >
            </el-form-item>
            <el-form-item label="Product Image">
              <ImageInput
                :image.sync="product.image_url"
                image-empty="/default/material-icon.jpg"
                @image-object="setImageObject"
              />
            </el-form-item>
            <el-form-item label="Product Name" prop="name">
              <el-input
                v-model="product.name"
                @focus="helpIndex = 'productName'"
              ></el-input>
            </el-form-item>
            <div class="multiple-column">
              <el-form-item label="SKU">
                <el-input
                  v-model="product.sku"
                  :disabled="isAutogenerateSKU"
                  @focus="helpIndex = 'sku'"
                ></el-input>
              </el-form-item>
              <div class="right">
                <el-checkbox v-model="isAutogenerateSKU"
                  >Autogenerate SKU</el-checkbox
                >
              </div>
            </div>
            <el-form-item label="Units" prop="units">
              <el-input
                v-model="product.units"
                @focus="helpIndex = 'units'"
              ></el-input>
            </el-form-item>
            <div class="multiple-column">
              <el-form-item class="inline-input" label="Brand">
                <el-select
                  v-model="product.brand_id"
                  :remote-method="searchBrand"
                  filterable
                  remote
                  style="width: 100%"
                  @focus="helpIndex = 'brand'"
                >
                  <el-option
                    v-for="brand in brands"
                    :key="brand.id"
                    :label="brand.name"
                    :value="brand.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <div class="right">
                <el-button
                  icon="el-icon-plus"
                  plain
                  size="small"
                  @click="newBrandModalVisible = true"
                  >New</el-button
                >
              </div>
            </div>
            <div class="multiple-column">
              <el-form-item class="inline-input" label="Product Type">
                <el-select
                  v-model="product.product_type_id"
                  :remote-method="searchProductType"
                  filterable
                  remote
                  style="width: 100%"
                  @focus="helpIndex = 'productType'"
                >
                  <el-option
                    v-for="productType in productTypes"
                    :key="productType.id"
                    :label="productType.name"
                    :value="productType.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <div class="right">
                <el-button
                  icon="el-icon-plus"
                  plain
                  size="small"
                  @click="newProductTypeModalVisible = true"
                  >New</el-button
                >
              </div>
            </div>
            <el-form-item
              label="Product Description"
              style="margin-bottom: 35px"
            >
              <el-input
                v-model="product.description"
                type="textarea"
                @focus="helpIndex = 'description'"
              ></el-input>
            </el-form-item>
          </el-form>
          <el-form
            ref="initialValue"
            :model="initialValue"
            :rules="initialRule"
            label-width="170px"
            size="medium"
          >
            <el-form-item class="mb-2">
              <span class="text-dark font-weight-normal"
                >Provide initial values to set your product up with:</span
              >
            </el-form-item>
            <el-form-item
              v-if="!product.is_dynamic"
              class="inline-input"
              label="Initial Stock"
              prop="stock_value"
            >
              <el-input
                v-model="initialValue.stock_value"
                @focus="helpIndex = 'initialStock'"
              ></el-input>
            </el-form-item>
            <el-form-item
              class="inline-input"
              label="Base Price"
              prop="stock_initial_price"
            >
              <el-input
                v-model="initialValue.stock_initial_price"
                @focus="helpIndex = 'initialCost'"
                ><template slot="prepend">₱</template></el-input
              >
            </el-form-item>
          </el-form>
          <br />
          <el-form
            ref="prices"
            :model="prices"
            label-width="170px"
            size="medium"
          >
            <el-form-item class="inline-input" label="Buy Price">
              <el-input
                v-model="prices.buy_price"
                @focus="helpIndex = 'buyPrice'"
                ><template slot="prepend">₱</template></el-input
              >
            </el-form-item>
            <el-form-item class="inline-input" label="Wholesale Price">
              <el-input
                v-model="prices.wholesale_price"
                @focus="helpIndex = 'wholeSalePrice'"
                ><template slot="prepend">₱</template></el-input
              >
            </el-form-item>
            <el-form-item class="inline-input" label="Retail Price">
              <el-input
                v-model="prices.retail_price"
                @focus="helpIndex = 'retailPrice'"
                ><template slot="prepend">₱</template></el-input
              >
            </el-form-item>
          </el-form>
          <el-form
            v-if="product.is_manufactured"
            ref="materials"
            :model="product"
            class="form-container mb-4"
            size="medium"
          >
            <el-form-item style="margin-bottom: 0px">
              <el-checkbox
                v-model="withMaterials"
                @change="helpIndex = 'materials'"
                >This product have connected materials</el-checkbox
              >
            </el-form-item>
            <div v-if="withMaterials">
              <div class="multiple-column">
                <div class="left">
                  <el-form-item class="mb-1" label="Quantity"> </el-form-item>
                </div>
                <div class="flex-grow">
                  <el-form-item class="mb-1 ml-4" label="Material Name">
                  </el-form-item>
                </div>
              </div>
              <div
                v-for="(material, index) in product.materialIds"
                :key="index"
                class="multiple-column"
              >
                <div class="left">
                  <el-form-item
                    :prop="`materialIds.${index}.quantity`"
                    :rules="{ required: true, message: 'Quantity is required' }"
                  >
                    <el-input v-model="material.quantity"></el-input>
                  </el-form-item>
                </div>
                <div class="flex-grow">
                  <el-form-item
                    :prop="`materialIds.${index}.variant_id`"
                    :rules="{ required: true, message: 'Material is required' }"
                    class="ml-4"
                  >
                    <el-select
                      v-model="material.variant_id"
                      :remote-method="searchVariant"
                      filterable
                      placeholder="Start typing SKU or Name"
                      remote
                      style="width: 100%"
                    >
                      <el-option
                        v-for="variant in pVariants"
                        :key="variant.id"
                        :label="variant.name"
                        :value="variant.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <div
                  v-if="index !== 0 && index === product.materialIds.length - 1"
                  class="delete-column"
                >
                  <el-form-item>
                    <el-button
                      class="ml-4"
                      icon="el-icon-delete"
                      type="danger"
                      @click="removeMaterial"
                    ></el-button>
                  </el-form-item>
                </div>
              </div>
              <gf-button type="primary" @click="addMaterial"
                >Add Material</gf-button
              >
            </div>
          </el-form>
          <el-form
            ref="options"
            :model="product"
            class="form-container mb-4"
            size="medium"
          >
            <el-form-item style="margin-bottom: 0px">
              <el-checkbox v-model="withOption" @change="helpIndex = 'variants'"
                >This product has multiple variants</el-checkbox
              >
            </el-form-item>
            <div v-if="withOption">
              <div class="multiple-column">
                <div class="left">
                  <el-form-item class="mb-1" label="Variant Option">
                  </el-form-item>
                </div>
                <div class="flex-grow">
                  <el-form-item class="mb-1 ml-4" label="Option Values">
                  </el-form-item>
                </div>
              </div>
              <div
                v-for="(option, index) in product.options"
                :key="index"
                class="multiple-column"
              >
                <div class="left">
                  <el-form-item
                    :prop="`options.${index}.name`"
                    :rules="{
                      required: true,
                      message: 'Option Name is required'
                    }"
                  >
                    <el-input v-model="option.name"></el-input>
                  </el-form-item>
                </div>
                <div class="flex-grow">
                  <el-form-item
                    :prop="`options.${index}.values`"
                    :rules="{
                      required: true,
                      message: 'Option Values is required'
                    }"
                    class="ml-4"
                  >
                    <el-select
                      v-model="option.values"
                      allow-create
                      default-first-option
                      filterable
                      multiple
                      style="width: 100%"
                    >
                      <el-option
                        v-for="(opt, i) in option.values"
                        :key="i"
                        :label="opt"
                        :value="opt"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <div
                  v-if="index !== 0 && index === product.options.length - 1"
                  class="delete-column"
                >
                  <el-form-item>
                    <el-button
                      class="ml-4"
                      icon="el-icon-delete"
                      type="danger"
                      @click="removeOption"
                    ></el-button>
                  </el-form-item>
                </div>
              </div>
              <gf-button type="primary" @click="addOption"
                >Add Option</gf-button
              >
            </div>
          </el-form>
          <el-form
            ref="packs"
            :model="product"
            class="form-container"
            size="medium"
          >
            <el-form-item style="margin-bottom: 0px">
              <el-checkbox
                v-model="withPack"
                size="medium"
                @change="helpIndex = 'packSize'"
                >This product is bought in and/or sold in packs</el-checkbox
              >
            </el-form-item>
            <div v-if="withPack">
              <div class="multiple-column">
                <div class="left">
                  <el-form-item class="mb-1" label="Pack Size Label">
                  </el-form-item>
                </div>
                <div class="flex-grow">
                  <el-form-item class="mb-1 ml-4" label="Pack Size Quantity">
                  </el-form-item>
                </div>
              </div>
              <div
                v-for="(pack, index) in product.packs"
                :key="index"
                class="multiple-column"
              >
                <div>
                  <el-form-item
                    :prop="`packs.${index}.label`"
                    :rules="{
                      required: true,
                      message: 'Pack Label is required'
                    }"
                  >
                    <el-input v-model="pack.label"></el-input>
                  </el-form-item>
                </div>
                <div class="flex-grow">
                  <el-form-item
                    :prop="`packs.${index}.size`"
                    :rules="{
                      required: true,
                      message: 'Pack Name is required'
                    }"
                    class="ml-4"
                  >
                    <el-input v-model="pack.size"></el-input>
                  </el-form-item>
                </div>
                <div
                  v-if="index !== 0 && index === product.packs.length - 1"
                  class="delete-column"
                >
                  <el-form-item>
                    <el-button
                      class="ml-4"
                      icon="el-icon-delete"
                      type="danger"
                      @click="removePack"
                    ></el-button>
                  </el-form-item>
                </div>
              </div>
              <gf-button type="primary" @click="addPack">Add Pack</gf-button>
            </div>
          </el-form>
          <template #footer>
            <gf-button
              v-if="withOption || withPack"
              class="mt-5"
              type="primary"
              @click="toSummary"
              >Continue to Summary</gf-button
            >
            <gf-button
              v-else
              class="mt-5"
              type="primary"
              @click="confirmCreateProduct"
              >Create Product</gf-button
            >
          </template>
        </gf-form>
        <template #aside>
          <gf-helper
            :content="helpInfo[helpIndex].content"
            :title="helpInfo[helpIndex].title"
          ></gf-helper>
        </template>
      </gf-container>
      <gf-content v-else v-loading="loading" overflow>
        <gf-table :data="variants">
          <el-table-column label="Variant / Pack Name" min-width="250">
            <template #default="slot">
              <span v-if="!slot.row.is_pack"
                >{{ product.name }} - {{ slot.row.name }}</span
              >
              <span v-else style="margin-left: 20px"
                >>> {{ slot.row.name }} (Pack of
                {{ $NumberFormat(slot.row.pack_size) }})</span
              >
            </template>
          </el-table-column>
          <el-table-column label="SKU" min-width="150" prop="sku">
            <template #default="slot">
              <el-input v-model="slot.row.sku" size="small"></el-input>
            </template>
          </el-table-column>
          <el-table-column
            v-if="!product.is_dynamic"
            label="Initial Stock"
            min-width="150"
            prop="stock"
          >
            <template #default="slot">
              <el-input
                v-if="slot.row.stock !== undefined"
                v-model="slot.row.stock"
                size="small"
              ></el-input>
              <div v-else style="text-align: center">--</div>
            </template>
          </el-table-column>
          <el-table-column label="Initial Cost" min-width="180" prop="cost">
            <template #default="slot">
              <el-input
                v-if="slot.row.cost !== undefined"
                v-model="slot.row.cost"
                size="small"
                ><template slot="prepend">₱</template></el-input
              >
              <div v-else style="text-align: center">--</div>
            </template>
          </el-table-column>
          <el-table-column label="Buy Price" min-width="180">
            <template #default="slot">
              <el-input v-model="slot.row.buyPrice" size="small"
                ><template slot="prepend">₱</template></el-input
              >
            </template>
          </el-table-column>
          <el-table-column label="Wholesale Price" min-width="180">
            <template #default="slot">
              <el-input v-model="slot.row.wholesalePrice" size="small"
                ><template slot="prepend">₱</template></el-input
              >
            </template>
          </el-table-column>
          <el-table-column label="Retail Price" min-width="150">
            <template #default="slot">
              <el-input v-model="slot.row.retailPrice" size="small"
                ><template slot="prepend">₱</template></el-input
              >
            </template>
          </el-table-column>
          <el-table-column width="100">
            <template #default="slot">
              <el-button
                icon="el-icon-delete"
                size="small"
                type="danger"
                @click="removeVariant(slot.$index)"
              ></el-button>
            </template>
          </el-table-column>
        </gf-table>
        <div class="mt-4">
          <gf-button type="primary" @click="createProductAndVariants"
            >Create Product</gf-button
          >
          <gf-button @click="summaryPage = false"
            >Back to Product Setup</gf-button
          >
        </div>
      </gf-content>
    </gf-card>
  </gf-base>
</template>

<style lang="scss" scoped>
.image-input {
  position: relative;
  display: inline-block;
  border-radius: 5.46px;

  img {
    border: 3px solid #fff;
    width: 120px;
    height: 120px;
    box-shadow: 0px 6px 10px 6px rgba(0, 0, 0, 0.075);
    border-radius: 5.46px;
  }

  .tool {
    box-shadow: 0px 9px 16px 0px rgba(24, 28, 50, 0.25) !important;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    cursor: pointer;
    background-color: #ffffff;
    border-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      color: #b5b5c3 !important;
      font-size: 12px;
    }

    &__change {
      position: absolute;
      top: -7px;
      right: -8px;

      input {
        width: 0 !important;
        height: 0 !important;
      }
    }

    &__remove {
      position: absolute;
      bottom: 7px;
      right: -8px;
    }
  }
}

.el-form-item {
  width: 630px;
}

.auto-form-item-width {
  .el-form-item {
    width: auto;
  }
}

.multiple-column {
  display: flex;
  align-items: center;

  .right {
    margin-bottom: 30px;
    margin-left: 15px;
  }

  .left {
    width: 30%;
  }

  .flex-grow {
    flex-grow: 1;
  }
}

.form-container {
  border: 1px solid #3a8ee6;
  border-radius: 2.5px;
  padding: 10px 20px;

  .el-form-item {
    width: auto;
    margin-bottom: 20px;
  }
}

.summary {
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__content {
    flex-grow: 1;
    height: 0;
    overflow: auto;

    &__header {
      &__content {
        &__title {
          font-size: 15px;
          font-weight: 400;

          span {
            font-size: 13px;
            margin-left: 5px;
            font-weight: 300;
          }
        }
      }
    }

    &__footer {
      padding: 20px 0;

      &__content {
        span {
          font-size: 15px;
          font-weight: 500;
          cursor: pointer;
          margin-left: 10px;
          color: #b5b5c3;
        }
      }
    }
  }
}
</style>

<script>
// Services
import BrandService from '@/services/v1/Brand'
import ProductTypeService from '@/services/v1/ProductType'
import ProductService from '@/services/v1/Product'
import VariantService from '@/services/v1/Variant'
import FileUploadService from '@/services/v2/FileUpload'

// Lodash
import { debounce } from 'lodash'
import ImageInput from '../../../../component-v2/ImageInput'
import BackButton from '../../../../component-v2/BackButton'

import help from './help'

export default {
  components: {
    ImageInput,
    BackButton
  },
  data() {
    return {
      // UI Control
      loading: false,
      isAutogenerateSKU: true,
      withMaterials: false,
      withOption: false,
      withPack: false,
      summaryPage: false,

      variantsName: [],

      brands: [],
      productTypes: [],
      pVariants: [],

      // Main
      image_object: null,
      product: {
        is_manufactured: false,
        is_draft: false,
        name: null,
        sku: null,
        units: null,
        brand_id: null,
        product_type_id: null,
        description: null,
        image_url: null,
        materialIds: [
          {
            variant_id: null,
            quantity: null
          }
        ],
        options: [
          {
            name: null,
            values: []
          }
        ],
        packs: [
          {
            label: null,
            size: null
          }
        ]
      },
      initialValue: {
        stock_value: null,
        stock_initial_price: 0
      },
      prices: {
        buy_price: 0,
        retail_price: 0,
        wholesale_price: 0
      },
      variants: [],

      // modal form
      brand: {
        name: null
      },
      productType: {
        name: null
      },

      // rules
      productRule: {
        name: [{ required: true, message: 'Name is require', trigger: 'blur' }],
        units: [
          { required: true, message: 'Units is require', trigger: 'blur' }
        ]
      },
      initialRule: {
        stock_value: [
          {
            required: true,
            message: 'Initial Stock is require',
            trigger: 'blur'
          }
        ]
      },
      brandRule: {
        name: [{ required: true, message: 'Name is require', trigger: 'blur' }]
      },
      productTypeRule: {
        name: [{ required: true, message: 'Name is require', trigger: 'blur' }]
      },

      // Model
      newBrandModalVisible: false,
      newBrandModalLoading: false,
      newProductTypeModalVisible: false,
      newProductTypeModalLoading: false,

      // Helper
      helpInfo: help,
      helpIndex: 'newProduct',

      // Filters
      brandFilter: {},
      productTypeFilter: {},
      variantFilter: {}
    }
  },
  watch: {
    'product.name': debounce(async function(value) {
      if (!this.product.name || this.product.name === '') return

      try {
        let sku = this.product.name
          .split(' ')
          .join('')
          .substring(0, 6)
          .toUpperCase()
        const pdService = new ProductService()
        const response = await pdService.getLastSKU(sku)
        const existingSKU = response.data.sku

        if (existingSKU) {
          const number = parseInt(existingSKU.substring(sku.length)) + 1
          sku += this.$Numeral(number).format('00')
        }

        this.product.sku = sku
      } catch (error) {
        this.$Error(error)
      }
    }, 400)
  },
  methods: {
    // handler
    back() {
      this.$router.back()
    },
    addOption() {
      this.product.options.push({ name: null, values: [] })
    },
    removeOption() {
      this.product.options.pop()
      this.generateVariants()
    },
    addPack() {
      this.product.packs.push({ size: null, label: null })
    },
    removePack(index) {
      this.product.packs.pop()
    },
    addMaterial() {
      this.product.materialIds.push({ quantity: null, variant_id: null })
    },
    removeMaterial(index) {
      this.product.materialIds.pop()
    },
    removeVariant(index) {
      if (this.variants[index].is_pack) {
        this.variants.splice(index, 1)
        return
      }

      let items = 1
      for (let i = index + 1; i < this.variants.length; i++) {
        if (!this.variants[i].is_pack) {
          break
        }

        items++
      }

      this.variants.splice(index, items)
    },
    setImageObject(file) {
      this.image_object = file
    },

    // Brand Modal
    newBrand() {
      this.$refs.brand
        .validate()
        .then(async () => {
          try {
            this.newBrandModalLoading = true

            const bdService = new BrandService()
            const response = await bdService.create(this.brand)
            this.$message.success(`${this.brand.name} successfully created`)

            this.newBrandModalVisible = false
            this.searchBrand(this.brand.name)

            this.product.brand_id = response.data.id
            this.$ClearField('brand')
          } catch (error) {
            this.systemError()
          } finally {
            this.newBrandModalLoading = false
          }
        })
        .catch(() => {})
    },

    // Product Type Modal
    newProductType() {
      this.$refs.productType
        .validate()
        .then(async () => {
          try {
            this.newProductTypeModalLoading = true

            const ptService = new ProductTypeService()
            const response = await ptService.create(this.productType)
            this.$message.success(
              `${this.productType.name} successfully created`
            )

            this.newProductTypeModalVisible = false
            this.searchProductType(this.productType.name)

            this.product.product_type_id = response.data.id
            this.$ClearField('productType')
          } catch (error) {
            this.$Error(error)
          } finally {
            this.newProductTypeModalLoading = false
          }
        })
        .catch(() => {})
    },

    // search
    searchBrand(query) {
      if (query !== '') {
        this.brandFilter.q = {
          like: query
        }
      } else delete this.brandFilter.q

      this.getBrands()
    },
    searchProductType(query) {
      if (query !== '') {
        this.productTypeFilter.q = {
          like: query
        }
      } else delete this.productTypeFilter.q

      this.getProductTypes()
    },
    async searchVariant(query) {
      try {
        if (query !== '') {
          this.variantFilter.q = {
            like: query
          }
        } else delete this.variantFilter.q

        await this.getVariants()
      } catch (error) {
        this.$Error(error)
      }
    },

    // supporting functions
    generateVariants() {
      const arrays = []
      this.variants = []

      for (let i = 0; i < this.product.options.length; i++) {
        arrays.push(this.product.options[i].values)
      }

      const combi = this.combination(arrays)

      for (let i = 0; i < combi.length; i++) {
        const name = combi[i]

        const sku = name
          .substring(1)
          .split(':')
          .map(sp => sp.substring(0, 1))
          .join('-')
        const optVal = name.substring(1).split(':')
        const opt = []

        for (let i = 0; i < optVal.length; i++) {
          opt.push({ name: this.product.options[i].name, value: optVal[i] })
        }

        const variant = {
          name: name.substring(1),
          sku: this.product.sku ? `${this.product.sku}-${sku}` : sku,
          stock: this.initialValue.stock_value,
          cost: this.initialValue.stock_initial_price,
          buyPrice: this.prices.buy_price,
          retailPrice: this.prices.retail_price,
          wholesalePrice: this.prices.wholesale_price,
          units: this.product.units,
          is_pack: false,
          options: opt
        }

        this.variants.push(variant)
      }
    },
    combination(arrays) {
      if (!arrays) return []
      if (!Array.isArray(arrays)) return []
      if (arrays.length === 0) return []

      for (let i = 0; i < arrays.length; i++) {
        if (!Array.isArray(arrays[i]) || arrays[i].length === 0) {
          return []
        }
      }

      const odometer = new Array(arrays.length)
      odometer.fill(0)

      const output = []

      let newCombination = this.formCombination(odometer, arrays)

      output.push(newCombination)

      while (this.odometerIncrement(odometer, arrays)) {
        newCombination = this.formCombination(odometer, arrays)
        output.push(newCombination)
      }

      return output
    },
    formCombination(odometer, arrays) {
      return odometer.reduce((accumulator, odometerValue, odometerIndex) => {
        return '' + accumulator + ':' + arrays[odometerIndex][odometerValue]
      }, '')
    },
    odometerIncrement(odometer, arrays) {
      for (
        let iOdometerDigit = odometer.length - 1;
        iOdometerDigit >= 0;
        iOdometerDigit--
      ) {
        const maxee = arrays[iOdometerDigit].length - 1
        if (odometer[iOdometerDigit] + 1 <= maxee) {
          odometer[iOdometerDigit]++
          return true
        } else {
          if (iOdometerDigit - 1 < 0) {
            return false
          } else {
            odometer[iOdometerDigit] = 0
          }
        }
      }
    },

    // Main
    toSummary() {
      this.$refs.product
        .validate()
        .then(() => {
          return this.$refs.initialValue.validate()
        })
        .then(() => {
          return this.$refs.options.validate()
        })
        .then(() => {
          return this.$refs.packs.validate()
        })
        .then(() => {
          if (this.withOption && !this.withPack) {
            this.generateVariants()
            this.summaryPage = true
            return
          }

          if (!this.withOption && this.withPack) {
            this.variants = []
            const variant = {
              name: this.product.name,
              sku: this.product.sku,
              stock: this.initialValue.stock_value,
              cost: this.initialValue.stock_initial_price,
              buyPrice: this.prices.buy_price,
              retailPrice: this.prices.retail_price,
              wholesalePrice: this.prices.wholesale_price,
              is_pack: false,
              units: this.product.units
            }

            this.variants.push(variant)

            for (let i = 0; i < this.product.packs.length; i++) {
              const option = this.product.packs[i]
              const packVariant = {
                name: option.label,
                pack_size: option.size,
                sku: variant.sku,
                buyPrice: this.prices.buy_price,
                retailPrice: this.prices.retail_price,
                wholesalePrice: this.prices.wholesale_price,
                is_pack: true,
                units: this.product.units
              }

              this.variants.push(packVariant)
            }

            this.summaryPage = true
            return
          }

          // True all
          if (this.withOption && this.withPack) {
            this.generateVariants()
            const length = this.variants.length
            for (let i = 0; i < this.product.packs.length; i++) {
              const option = this.product.packs[i]
              const packVariant = {
                name: option.label,
                pack_size: option.size,
                sku: null,
                buyPrice: this.prices.buy_price,
                retailPrice: this.prices.retail_price,
                wholesalePrice: this.prices.wholesale_price,
                is_pack: true,
                units: this.product.units
              }

              for (let j = 0; j < length; j++) {
                const index = j * (2 + i)
                packVariant.sku = this.variants[index].sku
                this.variants.splice(
                  index + (i + 1),
                  0,
                  Object.assign({}, packVariant)
                )
              }
            }

            this.summaryPage = true
          }
        })
        .catch(() => {})
    },
    confirmCreateProduct() {
      this.$refs.product
        .validate()
        .then(() => {
          return this.$refs.initialValue.validate()
        })
        .then(() => {
          if (
            this.product.is_manufactured &&
            this.product.materialIds.length > 0
          ) {
            this.$confirm(
              'You are creating a manufactured product with connected materials. Do you want to use existing stocks?',
              'Notification',
              {
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                type: 'info',
                distinguishCancelAndClose: true
              }
            )
              .then(() => {
                this.createProduct(true)
              })
              .catch(action => {
                if (action === 'cancel') this.createProduct(false)
              })
          } else {
            this.createProduct(false)
          }
        })
        .catch(() => {})
    },
    async createProduct(useStock) {
      try {
        this.loading = true
        const product = this.$_.cloneDeep(this.product)

        const variant = {
          name: this.product.name,
          sku: this.product.sku,
          initial_cost: this.initialValue.stock_initial_price,
          is_pack: false
        }

        this.$Sanitize(product)

        variant.prices = []
        variant.stocks = [
          {
            warehouse_id: 1,
            value: this.initialValue.stock_value || 0
          }
        ]

        if (this.prices.buy_price) {
          variant.prices.push({
            price_list_id: 1,
            value: this.prices.buy_price
          })
        }

        if (this.prices.wholesale_price) {
          variant.prices.push({
            price_list_id: 2,
            value: this.prices.wholesale_price
          })
        }

        if (this.prices.retail_price) {
          variant.prices.push({
            price_list_id: 3,
            value: this.prices.retail_price
          })
        }

        if (variant.prices.length === 0) delete variant.prices

        delete product.options
        delete product.packs

        if (!product.is_manufactured) {
          delete product.materialIds
        }

        const products = {
          useStock: useStock,
          product: product,
          variants: [variant]
        }

        // If there is an image uploaded
        if (this.image_object) {
          const fd = new FormData()
          fd.append('image', this.image_object)
          fd.append('folder', 'product')

          const fileUploadService = new FileUploadService()
          const result = await fileUploadService.upload(fd)
          product.image_url = result.data.path
        }

        const pdService = new ProductService()
        const response = await pdService.create(products)
        this.$message.success(`${this.product.name} successfully created`)
        await this.$router.push({
          name: 'product-detail',
          params: { id: response.data.id }
        })
      } catch (error) {
        this.$Error(error)
      } finally {
        this.loading = false
      }
    },
    async createProductAndVariants() {
      try {
        this.loading = true

        const product = this.$_.cloneDeep(this.product)
        const variants = this.$_.cloneDeep(this.variants)

        this.$Sanitize(product)

        if (this.withOption) {
          for (let i = 0; i < product.options.length; i++) {
            const opt = product.options[i]
            delete opt.values
          }
        } else {
          delete product.options
        }

        delete product.packs

        if (!product.is_manufactured) {
          delete product.materialIds
        }

        const vars = []
        for (let i = 0; i < variants.length; i++) {
          const vr = variants[i]

          const variant = {
            name: vr.name,
            sku: vr.sku,
            initial_cost: parseFloat(vr.cost) || null,
            is_pack: vr.is_pack,
            pack_size: vr.pack_size || 0,
            options: vr.options || []
          }

          this.$Sanitize(variant)

          variant.prices = []
          if (!variant.is_pack) {
            variant.stocks = [
              {
                warehouse_id: 1,
                value: parseFloat(vr.stock)
              }
            ]
          }

          if (vr.buyPrice) {
            variant.prices.push({
              price_list_id: 1,
              value: parseFloat(vr.buyPrice)
            })
          }

          if (vr.wholesalePrice) {
            variant.prices.push({
              price_list_id: 2,
              value: parseFloat(vr.wholesalePrice)
            })
          }

          if (vr.retailPrice) {
            variant.prices.push({
              price_list_id: 3,
              value: parseFloat(vr.retailPrice)
            })
          }

          if (variant.prices.length === 0) delete variant.prices
          if (variant.options.length === 0) delete variant.options

          vars.push(variant)
        }

        const products = {
          product: product,
          variants: vars
        }

        const pdService = new ProductService()
        const result = await pdService.create(products)
        this.$message.success(`${this.product.name} successfully created`)
        this.$router.push({
          name: 'product-detail',
          params: { id: result.data.id }
        })
      } catch (error) {
        this.$Error(error)
      } finally {
        this.loading = false
      }
    },

    // Services fetch
    async getBrands() {
      try {
        const bdService = new BrandService()
        const result = await bdService.list(null, null, this.brandFilter)
        this.brands = result.data.rows
      } catch (error) {
        this.$Error(error)
      }
    },
    async getProductTypes() {
      try {
        const ptService = new ProductTypeService()
        const result = await ptService.list(null, null, this.productTypeFilter)
        this.productTypes = result.data.rows
      } catch (error) {
        this.$Error(error)
      }
    },
    async getVariants() {
      try {
        const vrService = new VariantService()
        const result = await vrService.list(null, null, this.variantFilter)
        this.pVariants = result.data.rows
      } catch (error) {
        this.$Error(error)
      }
    }
  },
  async mounted() {
    await this.$store.dispatch(this.$SET_BREADCRUMB, [
      { title: 'Inventory', route: '' },
      { title: 'Products' },
      { title: 'Create' }
    ])
    await this.getBrands()
    await this.getProductTypes()
  }
}
</script>
