import Service from '../Service'

export default class extends Service {
  constructor (id) {
    const url = ['inventory', 'products']
    if (id) {
      url.push(id)
    }
    super(url)
  }

  setActive (data) {
    return this.post(data, '/active')
  }

  setInactive (data) {
    return this.post(data, '/inactive')
  }

  getLastSKU (data) {
    return this.get({ sku: data }, '/last-sku')
  }
}
